<template>
  <div>
    <div class="bannerTop">
      <div class="image-container">
        <img src="../static/images/3.png" alt="" class="bannerImg" />
        <div class="bannerAsk" @click="freeClick">立即咨询</div>
      </div>
    </div>
    <section class="solution-module solution-module1">
      <div class="main-content">
        <h3 class="module-title moduleSolution">智慧化服务，个性化体验</h3>
        <div class="solution-list solution-list2">
          <dl
            id="group_1"
            :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
          >
            <dd>
              <h3 class="solution-list-title solutionOperating">渠道管理</h3>
              <p class="solutionOperatingImmediately">一站式管理多个渠道</p>
              <p class="solution-list-points solutionOperatingIn">
                直连全球海量OTA平台
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                一站式管理酒店库存价格
              </p>
              <p class="solution-list-points solutionOperatingExact">
                住前咨询，实时在线，即时沟通
              </p>
              <p class="solution-list-points solutionOperatingRoom">
                一键分销全网，收入坐享其成
              </p>
              <p class="solution-list-points solutionOperatingRoom">
                依托社交流量，发展酒店直销
              </p>
            </dd>
            <dt>
              <img src="../static/images/4.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section
      class="solution-module solution-module2"
      id="group_2"
      :class="TwoShow ? 'animate__animated animate__slideInRight' : ''"
    >
      <div class="main-content">
        <div class="solution-list solution-list1">
          <dl>
            <dd>
              <h3 class="solution-list-title solutionOperating">经营管理</h3>
              <p class="solutionOperatingImmediately">
                成本、收益、客房管家三大服务
              </p>
              <p class="solution-list-points solutionOperatingIn">
                即时了解酒店收支详情，掌控酒店经营现状
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                入库、出库、盘点，轻松管理酒店物资库存
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                预算制定，实时监控，达成业绩
              </p>
              <p class="solution-list-points solutionOperatingExact">
                精准竞对、市场分析，智能定价
              </p>
              <p class="solution-list-points solutionOperatingRoom">
                客房管家，实现打扫、客耗在线管理
              </p>
            </dd>
            <dt>
              <img src="../static/images/5.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section
      class="solution-module solution-module1"
      id="group_3"
      :class="ThreeShow ? 'animate__animated animate__slideInLeft' : ''"
    >
      <div class="main-content">
        <div class="solution-list solution-list2">
          <dl>
            <dd>
              <h3 class="solution-list-title solutionOperating">供应链</h3>
              <p class="solutionOperatingImmediately">
                一站式采购平台，严选用品
              </p>
              <p class="solution-list-points solutionOperatingIn">
                严选供应链商品，精准匹配酒店需求
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                充分发挥集采优势，质高价廉
              </p>
              <p class="solution-list-points solutionOperatingExact">
                线上采购，简单、易用、快捷
              </p>
              <p class="solution-list-points solutionOperatingRoom">
                整合供应链和集采优势，保证品质的同时大大降低采购成本
              </p>
            </dd>
            <dt>
              <img src="../static/images/6.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section
      class="solution-module solution-module2"
      id="group_4"
      :class="FourShow ? 'animate__animated animate__slideInRight' : ''"
    >
      <div class="main-content">
        <div class="solution-list solution-list1">
          <dl>
            <dd>
              <h3 class="solution-list-title solutionOperating">核心业务</h3>
              <p class="solutionOperatingImmediately">
                高效的一站式工作台 酒店运营尽在掌握
              </p>
              <p class="solution-list-points solutionOperatingIn">
                多维度可视化报表系统，随时掌握经营动态
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                提供智能有效的行业收益优化产品
              </p>
              <p class="solution-list-points solutionOperatingExact">
                强大的零售系统，满足酒店多业态经营
              </p>
              <p class="solution-list-points solutionOperatingRoom">
                提供智能有效的行业收益优化产品，提升酒店收益
              </p>
            </dd>
            <dt>
              <img src="../static/images/7.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section
      class="solution-module solution-module1"
      id="group_5"
      :class="FiveShow ? 'animate__animated animate__slideInLeft' : ''"
    >
      <div class="main-content">
        <div class="solution-list solution-list2">
          <dl>
            <dd>
              <h3 class="solution-list-title solutionOperating">集团管理</h3>
              <p class="solutionOperatingImmediately">
                标准化经营方案，实现集团收益规模化
              </p>
              <p class="solution-list-points solutionOperatingIn">
                为集团经营提供强大的数据分析平台
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                多门店集中管控，提供标准化经营方案
              </p>
              <p class="solution-list-points solutionOperatingExact">
                整合多渠道资源，实现集团规模化收益
              </p>
              <p class="solution-list-points solutionOperatingRoom">
                多店会员权益互通，助力集团门店生意
              </p>
            </dd>
            <dt>
              <img src="../static/images/8.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section
      class="solution-module solution-module2"
      id="group_6"
      :class="SixShow ? 'animate__animated animate__slideInRight' : ''"
    >
      <div class="main-content">
        <div class="solution-list solution-list1">
          <dl>
            <dd>
              <h3 class="solution-list-title solutionOperating">智能硬件</h3>
              <p class="solutionOperatingImmediately">
                硬件对接，减少重复工作，提升效率
              </p>
              <p class="solution-list-points solutionOperatingIn">
                支持主流门锁、身份证阅读器、POS厂商硬件
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                支持智能化入住机、客控设备、电子房价牌
              </p>
            </dd>
            <dt>
              <img src="../static/images/9.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section
      class="solution-module solution-module1"
      id="group_7"
      :class="SevenShow ? 'animate__animated animate__slideInLeft' : ''"
    >
      <div class="main-content">
        <div class="solution-list solution-list2">
          <dl>
            <dd>
              <h3 class="solution-list-title solutionOperating">运营咨询</h3>
              <p class="solutionOperatingImmediately">为酒店提供培训指导</p>
              <p class="solution-list-points solutionOperatingIn">
                为酒店提供经营培训，提升管理水平
              </p>
              <p class="solution-list-points solutionOperatingBudget">
                行业趋势解析，动态资讯，定期分享
              </p>
              <p class="solution-list-points solutionOperatingExact">
                提供专业渠道运营指导，玩转线上OTA
              </p>
            </dd>
            <dt>
              <img src="../static/images/10.png" alt="" />
            </dt>
          </dl>
        </div>
      </div>
    </section>
    <section class="five-advantages">
      <div class="main-content">
        <h3 class="module-title fiveAdvantages">五大优势</h3>
        <div class="advantages-list advantages-list-first-row">
          <dl>
            <dt class="advantages-icon1"></dt>
            <dd class="advantages-title advantagesFullCoverage">全覆盖</dd>
            <dd class="advantages-desc advantagesCoverageHotel">
              覆盖酒店经营全场景
            </dd>
            <dd class="advantages-text advantagesPmsBase">
              从pms基础功能到多渠道直连、智能分销、直销、客房管家、客户管理、成本财务、收益管理、采购、硬件对接，全业务链条覆盖。
            </dd>
          </dl>
          <dl>
            <dt class="advantages-icon2"></dt>
            <dd class="advantages-title advantagesIntelligent">智能化</dd>
            <dd class="advantages-desc advantagesIntelligentTitle">
              提升管理效率 提供经营决策依据
            </dd>
            <dd class="advantages-text advantagesIntelligentDesc">
              基于行业大数据，通过智能建模分析，实现酒店定价、竞对分析、市场动态等智能产品，打造酒店智能化经营标杆。
            </dd>
          </dl>
          <dl>
            <dt class="advantages-icon3"></dt>
            <dd class="advantages-title advantagesMoreChannel">渠道广</dd>
            <dd class="advantages-desc advantagesMoreChannelTitle">
              多渠道组合投放 订单无忧
            </dd>
            <dd class="advantages-text advantagesMoreChannelDesc">
              合作全球主流OTA，一键直连；海量分销，一键上线；微官网直销，一键生成。
            </dd>
          </dl>
        </div>
        <div class="advantages-list advantages-list-second-row">
          <dl>
            <dt class="advantages-icon4"></dt>
            <dd class="advantages-title advantagesGlobal">国际化</dd>
            <dd class="advantages-desc advantagesGlobalTitle">
              管理全球酒店一触即达
            </dd>
            <dd class="advantages-text advantagesGlobalDesc">
              具备5种语言、全时区、全币种、多税率的云端处理能力，布局海外酒店无压力。
            </dd>
          </dl>
          <dl>
            <dt class="advantages-icon5"></dt>
            <dd class="advantages-title advantagesCloudService">云服务</dd>
            <dd class="advantages-desc advantagesCloudServiceTitle">
              云端pms 省成本 免维护
            </dd>
            <dd class="advantages-text advantagesCloudServiceDesc">
              saas服务，无需自行搭建服务器，成本低，部署快，数据更安全。
            </dd>
          </dl>
        </div>
      </div>
    </section>

    <section class="service-guarantee">
      <div class="main-content">
        <h3 class="module-title serviceGuaranteeTitle">服务保障</h3>
        <ul class="service-list">
          <li>
            <img src="../static/images/14.png" alt="" /><span
              class="serviceGuarantee1"
              >在线培训</span
            >
          </li>
          <li>
            <img src="../static/images/15.png" alt="" /><span
              class="serviceGuarantee2"
              >远程协助</span
            >
          </li>
          <li>
            <img src="../static/images/16.png" alt="" /><span
              class="serviceGuarantee3"
              >在线咨询</span
            >
          </li>
          <li>
            <img src="../static/images/17.png" alt="" /><span
              class="serviceGuarantee4"
              >VIP客服顾问</span
            >
          </li>
          <li>
            <img src="../static/images/18.png" alt="" /><span
              class="serviceGuarantee6"
              >上门服务</span
            >
          </li>
          <li>
            <img src="../static/images/19.png" alt="" /><span
              class="serviceGuarantee7"
              >定制开发</span
            >
          </li>
        </ul>
      </div>
    </section>

    <section class="footer-free-trial">
      <button
        class="footerApplyFreeTrial applyTrialBtn apply-trial"
        @click="freeClick"
      >
        申请免费试用
      </button>
    </section>

    <div class="section_32 flex-row">
      <div class="box_47 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="../static/images/13.png"
        />
        <span class="text_104">河南品讯网络科技有限公司</span>
        <span class="text_105">0379-61118888</span>
        <span class="paragraph_25"
          >地址：河南省洛阳市老城区青年创业大厦24层</span
        >
      </div>
      <div class="box_48 flex-col"></div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106">产品体系</span>
        <span
          class="text_107"
          @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
          >远程踏勘管理系统
        </span>
        <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
          >友推云·电商SaaS平台</span
        >
        <span class="text_107" @click="goOther('https://fastrms.hnpinxun.cn/')"
          >视频资源管理AI工具</span
        >
        <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
          >亥时学习平台</span
        >
        <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
          >文明积分银行</span
        >
        <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
          >邻里中心智慧平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
          >工厂生产管理系统</span
        >
        <span class="text_107" @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
          >酒店系统</span
        >
        <span class="text_107" @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
          >党员双报到</span
        >
        <span class="text_107" @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
          >货车道路救援系统</span
        >
        <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
          >招商宣传</span
        >
        <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
          >智慧环卫综合平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
          >家政行业公共服务平台</span
        >
      </div>

      <div class="box_49 flex-col"></div>
      <div class="box_50 flex-col justify-between">
        <img
          class="image_43"
          referrerpolicy="no-referrer"
          src="../static/images/2.png"
        />
        <span class="text_120">微信小程序</span>
      </div>
      <div class="box_51 flex-col justify-between">
        <img
          class="image_44"
          referrerpolicy="no-referrer"
          src="../static/images/1.png"
        />
        <span class="text_121">微信公众号</span>
      </div>
    </div>
    <div class="text-wrapper_19 flex-col">
      <span class="text_44"
        >Copyright&nbsp;©&nbsp;2024&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      OneShow: true,
      TwoShow: false,
      ThreeShow: false,
      FourShow: false,
      FiveShow: false,
      SixShow: false,
      SevenShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  BeforeUnloadEvent() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      var group1Top = document.getElementById("group_1").offsetTop;
      const isGroupVisible =
        scrollPosition >= group1Top - window.innerHeight &&
        scrollPosition < group1Top;

      if (isGroupVisible && !this.OneShow) {
        this.OneShow = true;
      } else if (scrollPosition === 0) {
        this.OneShow = false;
      }
      //
      var group2Top = document.getElementById("group_2").offsetTop;
      const isGroupVisible2 =
        scrollPosition >= group2Top - window.innerHeight &&
        scrollPosition < group2Top;

      if (isGroupVisible2 && !this.TwoShow) {
        this.TwoShow = true;
      } else if (scrollPosition === 0) {
        this.TwoShow = false;
      }

      //
      var group3Top = document.getElementById("group_3").offsetTop;
      const isGroupVisible3 =
        scrollPosition >= group3Top - window.innerHeight &&
        scrollPosition < group3Top;

      if (isGroupVisible3 && !this.ThreeShow) {
        this.ThreeShow = true;
      } else if (scrollPosition === 0) {
        this.ThreeShow = false;
      }

      //
      var group4Top = document.getElementById("group_4").offsetTop;
      const isGroupVisible4 =
        scrollPosition >= group4Top - window.innerHeight &&
        scrollPosition < group4Top;

      if (isGroupVisible4 && !this.FourShow) {
        this.FourShow = true;
      } else if (scrollPosition === 0) {
        this.FourShow = false;
      }

      //
      var group5Top = document.getElementById("group_5").offsetTop;
      const isGroupVisible5 =
        scrollPosition >= group5Top - window.innerHeight &&
        scrollPosition < group5Top;

      if (isGroupVisible5 && !this.FiveShow) {
        this.FiveShow = true;
      } else if (scrollPosition === 0) {
        this.FiveShow = false;
      }

      //
      var group6Top = document.getElementById("group_6").offsetTop;
      const isGroupVisible6 =
        scrollPosition >= group6Top - window.innerHeight &&
        scrollPosition < group6Top;

      if (isGroupVisible6 && !this.SixShow) {
        this.SixShow = true;
      } else if (scrollPosition === 0) {
        this.SixShow = false;
      }

      //
      var group7Top = document.getElementById("group_7").offsetTop;
      const isGroupVisible7 =
        scrollPosition >= group7Top - window.innerHeight &&
        scrollPosition < group7Top;

      if (isGroupVisible7 && !this.SevenShow) {
        this.SevenShow = true;
      } else if (scrollPosition === 0) {
        this.SevenShow = false;
      }
    },
    freeClick() {
      window.open("http://www.hnpinxun.cn/#/", "_blank");
    },
    goOther(e) {
      window.open(e, "_blank");
    },
  },
};
</script>

<style scoped>
@import "../static/css/index.css";
@import "../static/css/common.css";

.bannerTop {
  position: relative;
  height: 33vw;
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  position: relative;
}
.bannerAsk {
  width: 8.85vw;
  height: 2.8vw;
  border: 2px solid #0b74ef;
  border-radius: 15px;
  font-size: 1.14vw;
  font-family: MiSans, MiSans-400;
  font-weight: 400;
  text-align: CENTER;
  color: #0b74ef;
  line-height: 2.8vw;
  margin: 2.96vw auto 1.04vw auto;
  cursor: pointer;
  position: absolute;
  left: 21.09vw;
  bottom: 3.64vw;
  transform: translateX(-50%);
}
.bannerAsk:hover {
  background: #0b74ef;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .bannerTop {
    display: none;
  }
}
/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #0b74ef;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}
.text-wrapper_19 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  margin-top: -0.05vw;
}

.text_44 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
</style>
